
import './App.css';
import React from 'react';
import doda1 from './doda1.png'
import doda2 from './doda2.png'
import doda3 from './doda3.png'
import doda4 from './doda4.png'
import styled from 'styled-components'

const Frame = styled.div`
.image{
  width:100%;
 
}
.footer{
    width: 100%;
    margin-right: 20px;
    height: 125px;
    background-color: #121619;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    .footer-text{
      font-size: 20px;
      color:#fff;
    }
  }

`
function App() {
  return (
    <Frame>
     <img className='image' src={doda1} alt=''></img>
     <img className='image' src={doda2} alt=''></img>
     <img className='image' src={doda3} alt=''></img>
     <img className='image' src={doda4} alt=''></img>
     <div className='footer'>
      <div className='footer-text'>
        (주) 에스피에스홀딩스 SPS HODINGS.CORP
      </div>
      <div className='footer-text'>
        사업자 등록번호 : 761-88-01984
      </div>
      <div className='footer-text'>
        대표자 : 이지행
      </div>
      <div className='footer-text'>
        사업장 : 경기도 김포시 고촌읍 장차로5번길 9, 1동 402호(라르 플레이스 김포)
      </div>
      <div className='footer-text'>
        개업일 : 2021. 01. 27.
      </div>
     </div>
    </Frame>
  );
}

export default App;
